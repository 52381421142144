import { Injectable, NgZone, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ProductorService implements OnInit {

  private REST_API_SERVER = environment.apis;

  constructor(public httpClient: HttpClient,
    public router: Router,
    public ngZone: NgZone) { }


  ngOnInit(): void { }


  /**
   * Create productor
   * @param data 
   * @returns 
   */
  public createProductor(data: any) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/cProductor", data);
  }

  /**
   * Crear tostador
   * @param data 
   * @returns 
   */
  public createTostador(data: any) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/cTostador", data);
  }

  /**
   * Subir los certificados
   * @param data 
   * @returns 
   */
  public uploadCertificates(data: any) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/uploadCertificates", data);
  }

  /**
 * Subir los certificados
 * @param data 
 * @returns 
 */
  public uploadPremios(data: any) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/uploadPremios", data);
  }


  /**
* Subir los certificados
* @param data 
* @returns 
*/
  public uploadGallery(data: any) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/uploadGallery", data);
  }

  /**
* Traer datos del usuario - TOSTADOR
* @param data 
* @returns 
*/
  public loadDataTostador(data: any) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/loadDataTostador", data);
  }

  /**
* Traer datos del usuario - PRODUCTOR
* @param data 
* @returns 
*/
  public loadDataProductor(data: any) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/loadDataProductor", data);
  }


  /**
* Actualiza datos tostador
* @param data 
* @returns 
*/
  public updateTostador(data: any) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/uTostadorDatosBasicos", data);
  }



}
